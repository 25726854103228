import './styles.scss'

import { AuthContext, actionTypes } from '@Contexts/Auth'
import { Field, Form } from 'react-final-form'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { faBan, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { handleUserDeauth } from '@Services/handleUserDeauth'
import { httpPost } from '@Services/API'
import { logError } from '@Services/logging'
import { navigate } from 'gatsby'

const required = value => (value ? undefined : 'Required')

export const LoginForm = ({ hash }) => {
  const [loading, setLoading] = useState(false)
  const [errorCode, setErrorCode] = useState()
  const { dispatch } = useContext(AuthContext)

  useEffect(() => {
    if (!hash) return

    const params = new URLSearchParams(hash.replace('#', '?'));

    (async () => {
      await onSubmit({
        username: params.get('username'),
        pswd: params.get('pswd')
      })
    })()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async values => {
    setLoading(true)
    setErrorCode(undefined)

    try {
      const response = await httpPost('/login', values)
      dispatch({
        type: actionTypes.AUTHORIZE,
        token: response.token,
        username: values.username
      })
      navigate('/Search/')
    } catch (error) {
      handleUserDeauth(error, dispatch)
      setErrorCode(error.response.status)
      logError(error)
      setLoading(false) //State doesn't need updating on successful branch.
    }
  }

  return <div>
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) =>
        <form onSubmit={handleSubmit}>
          <div className='text-center login-input'>
            <Field name='username' validate={required}>
              {({ input, meta }) => <Fragment>
                <input {...input} type='text' placeholder='User Name' />
                {meta.error && meta.touched &&
                  <FontAwesomeIcon
                    data-testid='warning-icon'
                    className='login-input-error'
                    icon={faExclamationTriangle}
                    inverse
                    data-error-text={meta.error}
                    title={meta.error}
                  />}
              </Fragment>}
            </Field>
          </div>
          <div className='text-center login-input'>
            <Field name='pswd' validate={required}>
              {({ input, meta }) => <Fragment>
                <input {...input} type='password' placeholder='Password' />
                {meta.error && meta.touched &&
                  <FontAwesomeIcon
                    data-testid='warning-icon'
                    className='login-input-error'
                    icon={faExclamationTriangle}
                    inverse
                    data-error-text={meta.error}
                    title={meta.error}
                  />}
              </Fragment>}
            </Field>
            {loading && <div className='loading-ring-container'>
              <div className='loading-ring' />
            </div>}
          </div>
          {errorCode === 401 &&
            <div className='text-center login-error'>
              <FontAwesomeIcon data-testid='error-icon' icon={faBan} />
              <span>Invalid Username or Password</span>
            </div>
          }
          <div className='login-button' style={{ marginTop: `${errorCode ? 21 : 45}px` }}>
            <button>SIGN IN</button>
          </div>
        </form>
      }
    />
  </div>
}
