import '@Components/Pages/Login/styles.scss'

import { BackgroundImage, LoginForm } from '@Components/Pages/Login'
import { Col, Row } from 'react-bootstrap'

import { CarvantedgeLogo } from '@Components/shared/Images'
import React from 'react'
import StaticTestimonials from '@Components/shared/Testimonial/StaticTestimonials'

const Login = ({ location }) => <div>
  <CarvantedgeLogo location='login' style={{ margin: '0.9em auto', display: 'block' }} />
  <div className='login-container'>
    <BackgroundImage className='background' />
    <div className='login-container-header text-center'>
      Join. Find. Reserve. Purchase.
  <div className='login-container-header-2'>
        Corporate Cars. Expertly Maintained.
  </div>
    </div>
    <Row>
      <Col
        lg={{ span: 6, offset: 3 }}
        md={{ span: 6, offset: 3 }}
        sm={{ span: 8, offset: 2 }}
        xs={{ span: 10, offset: 1 }}
        className='login-container-form'
      >
        <div className='login-container-form-title text-center'>Sign in to search...</div>
        <LoginForm hash={location?.state?.hash} />
      </Col>
    </Row>
  </div>
  <div className='login-testimonials'>
    <StaticTestimonials />
  </div>
</div>

export default Login
