import { graphql, useStaticQuery } from 'gatsby'

import React from 'react'

export const BackgroundImage = props => {
    const data = useStaticQuery(graphql`
    query {
        file(relativePath: { eq: "background.png" }) {
            childImageSharp {
                fixed(width: 1200, height: 654) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
    `)
    const { src, srcWebp } = data.file.childImageSharp.fixed
    return <picture>
        <source srcSet={srcWebp} />
        <img {...props} src={src} alt='' />
    </picture>
}